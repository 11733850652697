import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';

const customerKey = 'cloudburrito-dev-customer';

if (
	location.hostname === 'infinity.cloudburrito.com' &&
	location.hash.startsWith('#/branchselector/')
) {
	const branch = location.hash.replace('#/branchselector/', '');
	const customer = prompt(
		`Enter a customer account to test branch "${branch}":`,
		getFromLocalStorage(customerKey) || ''
	);
	if (branch && customer) {
		setInLocalStorage(customerKey, customer);
		location.href = `https://${customer}.cloudburrito.com/Citrix/StoreWeb/?branch=${branch}#/`;
	} else {
		location.hash = '#';
	}
}
