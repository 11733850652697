import * as Cookie from 'js-cookie';

export interface StorageInterface {
	get: (key: string, clear?: boolean) => any;
	set: (key: string, data: any) => void;
	remove: (key: string) => void;
	preloadFromNative: () => Promise<void>;
}

const canUseLocalStorage = (function () {
	try {
		const testKey = 'SomeTestKey';
		localStorage.setItem(testKey, 'value');
		const value = localStorage.getItem(testKey);
		localStorage.removeItem(testKey);
		return value === 'value';
	} catch {
		return false;
	}
})();

const canUseSessionStorage = (function () {
	try {
		const testKey = 'SomeTestKey';
		sessionStorage.setItem(testKey, 'value');
		const value = sessionStorage.getItem(testKey);
		sessionStorage.removeItem(testKey);
		return value === 'value';
	} catch {
		return false;
	}
})();

const cookieVersion = '1';
function fallbackKey(key: string) {
	return cookieVersion + key;
}

const localStore: Storage = canUseLocalStorage
	? localStorage
	: {
			clear() {},
			getItem(key: string) {
				return Cookie.get(fallbackKey(key));
			},
			key(index: number) {
				return '' + index;
			},
			length: 0,
			removeItem(key: string) {
				Cookie.remove(fallbackKey(key), { path: '/' });
			},
			setItem(key: string, data: string) {
				let isSecured = true;
				if (location.protocol === 'http:' && IS_ON_PREM) {
					isSecured = false;
				}
				Cookie.set(fallbackKey(key), data, {
					secure: isSecured,
					expires: 3650,
					path: '/',
				});
			},
	  };

const memorySessionStorage = new Map<string, string>();
const sessionStore: Storage = canUseSessionStorage
	? sessionStorage
	: {
			clear() {
				memorySessionStorage.clear();
			},
			getItem(key: string) {
				return memorySessionStorage.get(key);
			},
			key(index: number) {
				return '' + index;
			},
			length: 0,
			removeItem(key: string) {
				memorySessionStorage.delete(key);
			},
			setItem(key: string, data: string) {
				memorySessionStorage.set(key, data);
			},
	  };

function getFromStorage(dataStore: Storage, key: string, clear: boolean) {
	const data = dataStore.getItem(key);
	let obj: any;
	try {
		obj = JSON.parse(data);
	} catch (e) {
		obj = data;
	}
	if (clear) {
		clearFromStorage(dataStore, key);
	}
	return obj;
}

function setInStorage(dataStore: Storage, key: string, data: any) {
	if (typeof data !== 'string') {
		dataStore.setItem(key, JSON.stringify(data));
	} else {
		dataStore.setItem(key, data);
	}
}

function clearFromStorage(dataStore: Storage, key: string) {
	dataStore.removeItem(key);
}

export function clearSessionStorage() {
	sessionStore.clear();
}

export function getFromSessionStorage<T>(key: string, clear = false): T {
	return getFromStorage(sessionStore, key, clear);
}

export function setInSessionStorage(key: string, data: any) {
	setInStorage(sessionStore, key, data);
}

export function removeFromSessionStorage(key: string) {
	clearFromStorage(sessionStore, key);
}

export const defaultLocalStorageFunctions: StorageInterface = {
	get: (key: string, clear: boolean) => {
		const ret = getFromStorage(localStore, key, clear);
		return ret;
	},
	set: (key: string, data: any) => {
		setInStorage(localStore, key, data);
	},
	remove: (key: string) => {
		clearFromStorage(localStore, key);
	},
	async preloadFromNative() {},
};

let environmentSpecificStorage = defaultLocalStorageFunctions;

export async function setEnvironmentSpecificStorage(
	envSpecificStorage: StorageInterface
) {
	await envSpecificStorage.preloadFromNative();
	environmentSpecificStorage = envSpecificStorage;
}

export function getFromLocalStorage<T>(key: string, clear = false): T {
	return environmentSpecificStorage.get(key, clear);
}

export function setInLocalStorage(key: string, data: any) {
	environmentSpecificStorage.set(key, data);
}

export function removeFromLocalStorage(key: string) {
	environmentSpecificStorage.remove(key);
}

export function getAllFromLocalStorage() {
	return Object.keys(localStore).reduce<object>(
		(obj, key) => ({
			...obj,
			[key]: localStore[key],
		}),
		{}
	);
}

export function isLocalStorageAvailable() {
	return canUseLocalStorage;
}
